import styled, { css } from "styled-components";
import bkImage from "../../../../static/entete-image-offre.jpg";
import imgOverBk from "../../../../static/resolu-entete-offre.png";
import shareBKImage from "../../../../static/carte-vert.png";
export const $OfferDetail = styled.header`
  .offer-detail-container {
    padding: 0;
    /* div {
      margin-bottom: 0;
      background-color: #fff;
    } */
    .image-header {
      height: 360px;
      width: 100%;
      position: relative;
      @media only screen and (max-width: 768px) {
        height: 175px;
      }

      .over-bk-image {
        background: url(${imgOverBk}) no-repeat;
        position: absolute;
        background-size: contain;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
      }
      .bk-image {
        background: url(${bkImage}) no-repeat center center;
        background-size: cover;
        width: 100%;
        height: 100%;
      }
    }
    .poste-name {
      background: transparent
        linear-gradient(103deg, var(--unnamed-color-033024) 0%, #118738 100%) 0%
        0% no-repeat padding-box;
      background: transparent linear-gradient(103deg, #033024 0%, #118738 100%)
        0% 0% no-repeat padding-box;
      @media only screen and (min-width: 768px) {
        margin-top: 2em;
      }
      h1 {
        color: #fff;
        padding: 0.5em 10%;
        @media only screen and (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .city-container {
      display: flex;
      align-items: center;
      text-transform: none;
      text-decoration: none;
      color: #000;
      margin: 0.6em;
      padding: 1em 0.3em;
      font-size: 1.75rem;
      font-weight: bold;
      display: flex;
      background-color: #f4f4f2;
      font-size: 26px !important;
      @media only screen and (min-width: 768px) {
        font-size: 30px !important;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 34px !important;
      }

      svg {
        margin-right: 0.5rem;
        fill: #eb5536;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .share-container {
      text-align: center;
      width: 100%;
      padding: 2em;
      position: relative;
      background: url(${shareBKImage}) no-repeat bottom;
      background-size: cover;
      .title {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
      .share-list {
        margin: 1em 0 0;
        display: flex;
        justify-content: center;
        button {
          margin: 0 0.3em;
        }
      }
    }
  }
  .footer-img-container img {
    width: 100%;
    max-height: 300px;
    padding: 15px 0;
  }

  .offer-detail-body {
    padding: 0 2%;
    .rightSection {
      @media only screen and (max-width: 768px) {
        padding: 0%;
      }
    }
    @media only screen and (min-width: 1025px) {
      padding: 0 10%;
    }
  }

  .postulationCTA {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.6em 2em;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    background: transparent linear-gradient(105deg, #eb5536 0%, #ff886f 100%) 0%
      0% no-repeat padding-box;
    border-radius: 5em;
    border: none;

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }

    svg {
      margin: 0 0 0 1em;
      transform: rotate(180deg);
      fill: #fff;
    }
    &:hover {
      background: #eb5536;
    }
  }
  .isMobile {
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
  .line {
    margin: 1em 0;
    border-bottom: 1px solid #eb5536;
    padding: 0.4em 0;
  }

  .apply_back_to_offers {
    display: flex;
    width: 100%;
    padding: 0;

    .soumettre-ma-candidature {
      width: 60%;
      padding: 1em 1em;
      background: #bad80a;
      align-items: center;
      display: flex;
      flex-direction: column;
      transition: 0.5s;

      .postulationCTA {
        width: 50%;
      }

      h3 {
        text-transform: uppercase;
        margin-bottom: 1em;
      }

      &:hover {
        width: 70%;
      }
    }

    .back-to-offers {
      cursor: pointer;
      padding-left: 4%;
      width: 40%;
      font-size: 1.4em;
      background: #28809b;
      color: #fff;
      display: flex;
      align-items: center;
      transition: 0.5s;

      .arrow-back-icon {
        float: left;
        height: auto;
        margin: 2px 15px 0 0;
        width: 1.7em;
        margin: 0;
      }

      div {
        line-height: 1;
        background: #28809b;
      }
      span {
        text-transform: uppercase;
        font-weight: 600;
      }

      &:hover {
        width: 50%;
      }
    }
  }
`;
