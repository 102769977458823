import styled, { css } from "styled-components";
import GlobalColors from "../../../client/data/GlobalColor";
import BGImageCandidatureSpontanee from "../../../../static/candiaturespontanee.jpg";
// import imgBackground from "../../../../static/";
export const $CandidatureSpontanee = styled.div`
  background: url(${BGImageCandidatureSpontanee}) no-repeat center;
  background-size: cover;
  padding: 5em 0;
  /* margin-bottom: 1em; */
  .container-candidature-spontanee {
    max-width: 55em;
    margin: 3em auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    h3 {
      /* font-size: 50px; */
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2.65em;
        height: 2.65em;
        border: solid 1px #118738;
        border-radius: 50em;
        -webkit-transform: translate(-50%, -35%);
        -ms-transform: translate(-50%, -35%);
        transform: translate(-50%, -35%);
      }
      /* @media only screen and (max-width: 768px) {
        font-size: 1.8rem;
      } */
    }
    .text {
      padding: 0.5em 0;
      font-size: 30px;
      @media only screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
    .postulationCTA {
      width: 50%;
      min-width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      /* width: 100%; */
      padding: 0.6em 2em;
      text-align: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      background: transparent linear-gradient(105deg, #eb5536 0%, #ff886f 100%)
        0% 0% no-repeat padding-box;
      border-radius: 5em;
      border: none;
      @media only screen and (min-width: 600px) {
        min-width: 400px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }

      svg {
        margin: 0 0 0 1em;
        transform: rotate(180deg);
        fill: #fff;
      }
      &:hover {
        background: #eb5536;
      }
    }
  }
`;
