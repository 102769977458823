import React from "react";
import SEO from "../../seo";
import BaseLayout from "../../BaseLayout";
import FeatureImage from "../featureImage/FeatureImage";
import TextBlock from "../../dependentComponent/textblock/TextBlock";
import ContentBlock from "../../dependentComponent/contentblock/ContentBlock";
import ContentBlockBulletedList from "../../dependentComponent/contentblockbulletedlist/ContentBlockBulletedList";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import Application from "../offerApplication/index";
import getUrlParameter from "../../dependentComponent/functions/getParamsValue";
import SimilarOffer from "./similarOffer/SimilarOffer";
import { $OfferDetail } from "./SC_OfferDetail";
import { ReactSVG } from "react-svg";

import CandidatureSpontanee from "../candidatureSpontanee/CandidatureSpontanee";
import WorkIcon from "@material-ui/icons/Work";
const { useRef } = React;

const offerNotSuitableForTheContact_OfferId = 7720;

function OfferFooterBlock(props) {
  const handleEmailClick = (event) => {
    event.preventDefault();
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailRegex.test(props.emailValue)) {
      props.applicationRef.current.handleOfferApplicationVisibility(true);
    } else {
      alert("Veuillez entrer une adresse courriel valide");
    }
  };
  const {
    url = String(window.location),
    title = "Steadylearner Website",
    shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
    size = "2.5rem",
  } = this.props;

  // const ShareList = Passers({
  //   url,
  //   className: "network__share-button"
  // })({
  //   className: "network cursor-pointer hover transition--default",
  //   title: `Share ${String(window.location)}`
  // })("li");

  return (
    <>
      <Col sm={12} md={5} className="footer-img-container">
        <Image src={props.offerFooterImgSrc} />
      </Col>
      <SimilarOffer
        fechedOfferList={props.fechedOfferList}
        actualOffer={props.actualOffer}
      />
      <Col sm={12} md={7}>
        <TextBlock title="Ce poste ne vous convient pas?" />
        <div className="courrier">
          <Form onSubmit={handleEmailClick}>
            <Form.Row className="align-items-center">
              <Col sm={9}>
                <Form.Control
                  className="mb-2"
                  id="inlineFormInput"
                  placeholder="Adresse courriel personnelle"
                  value={props.emailValue}
                  onChange={(e) => {
                    props.setEmailValue(e.target.value.trim());
                  }}
                />
              </Col>
              <Col sm={3}>
                <Button
                  type="submit"
                  className="mb-2"
                  onClick={handleEmailClick}
                >
                  Valider
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </Col>
    </>
  );
}

function OfferDetail({ pageContext: { offer, fechedOfferList } }) {
  const isBrowser = () => typeof window !== "undefined";
  isBrowser() && window.location.assign("https://www.pfresolu.com/Carrieres/");

  const offerHeaderImgSrc = null;
  const offerFooterImgSrc =
    "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22118%22%20height%3D%22250%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20118%20250%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1730634ab5e%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A13pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1730634ab5e%22%3E%3Crect%20width%3D%22118%22%20height%3D%22250%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2226.390625%22%20y%3D%22131%22%3E118x250%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E";
  const videoLinkExist = true;
  const [emailValue, setEmailValue] = React.useState("");
  const childRef = useRef();

  const GoogleMapLink = {
    "Saint-Félicien":
      "https://www.google.ca/maps/dir//900+Boulevard+Hamel,+Saint-F%C3%A9licien,+QC+G8K+2X4/@48.6415334,-72.4461744,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc207b8efa71897:0x154626a96c2039e4!2m2!1d-72.4439857!2d48.6415299!3e0",
    "La Doré":
      "https://www.google.ca/maps/dir//5850+Avenue+des+Jardins,+La+Dor%C3%A9,+QC+G8J+1B2/@48.7165225,-72.6478604,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9e0146ce14593:0x6d822fcfb01103de!2m2!1d-72.643483!2d48.7165155!3e0",
    Normandin:
      "https://www.google.ca/maps/dir//1165+Rue+Industrielle,+Normandin,+Qu%C3%A9bec+G8M+4S9,+Canada/@48.8293908,-72.5211239,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9e6397cad3b43:0x3985a43625900533!2m2!1d-72.5167465!2d48.8293838!3e0",
    Mistassini:
      "https://www.google.ca/maps/dir//200+Rue+de+Quen,+Dolbeau-Mistassini,+Qu%C3%A9bec+G8L+5M8,+Canada/@48.8833343,-72.271906,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9f3ff57eb6f6f:0x228f611fd67400e4!2m2!1d-72.201866!2d48.8832426!3e0",
    Girardville:
      "https://www.google.ca/maps/dir//2250+Rang+Saint+Joseph+Nord,+Girardville,+Qu%C3%A9bec+G0W+1R0,+Canada/@49.1266395,-72.5993328,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9ba367507710f:0x2d231494e51a7309!2m2!1d-72.5949554!2d49.1266326!3e0",

    "Saint-Thomas-Didyme":
      "https://www.google.ca/maps/dir//300+Route+du+Moulin,+Saint-Thomas-Didyme,+QC/@48.9245505,-72.6374028,16z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x4ce9c38fe5540487:0xd6448c2de6b281fb!2m2!1d-72.6330254!2d48.9245506!3e0",

    "Saint-Prime":
      "https://www.google.ca/maps/dir//101+Rue+du+Parc-Industriel,+Saint-Prime,+QC+G8J+1Y6/@48.5916763,-72.323252,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc201a22a5e987b:0xa5695a066f668e3c!2m2!1d-72.3188746!2d48.5916693!3e0",
    Larouche:
      "https://www.google.ca/maps/dir//900+Chemin+du+Lac+Hippolyte,+Larouche,+QC+G0W+1Z0/@48.4460482,-71.5132886,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc1d41030e1dd2f:0x59e58b26b530bb90!2m2!1d-71.5089112!2d48.4460412!3e0",

    Alma: "https://www.google.com/maps/dir//1100+Rue+Melan%C3%A7on+O,+Alma,+QC+G8B+5R7/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4cea763872498b1f:0xe60e17abb8336725?sa=X&ved=2ahUKEwjZ-Iyh0JfvAhVZGVkFHckVCrIQwwUwAHoECAUQAw",
    Dolbeau:
      "https://www.google.com/maps/dir//1+4e+Av,+Dolbeau-Mistassini,+QC+G8L+1W3/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4ce9f159c6934373:0xf0b1baa4b9ed4343?sa=X&ved=2ahUKEwig94W80JfvAhXRFFkFHcjFAdsQwwUwAHoECAUQAw",
    Kénogami:
      "https://www.google.com/maps/dir//3750+Rue+de+Champlain,+Saguenay,+QC/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4cc02903bf3c6233:0x5bc3c09a065692e6?sa=X&ved=2ahUKEwjV_qjV0JfvAhWaMlkFHbFLBlMQwwUwAHoECAUQAw",
    "Saint-Félicien":
      "https://www.google.com/maps/dir/46.8325302,-71.2451591/4000+Rte+chemin+Saint-Eus%C3%A8be+Saint-F%C3%A9licien+(Qu%C3%A9bec)+G8K+2R6+Canada/@47.7800143,-72.6343166,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x4cc207f72845e17f:0xfd511746e864bc24!2m2!1d-72.5147824!2d48.7448961",
  };

  if (!offer) return <></>;
  return (
    <BaseLayout>
      <SEO description={`${offer.postUrlPermLink}`} title="Resolu" />
      <$OfferDetail>
        <Container className="offer-detail-container" fluid>
          {offerHeaderImgSrc ? (
            <Row>
              {" "}
              <Col sm={12}>
                {" "}
                <FeatureImage headerImageSrc={offerHeaderImgSrc} />{" "}
              </Col>{" "}
            </Row>
          ) : null}
          <Row>
            <Col sm={12} style={{ padding: "0" }}>
              <FeatureImage />
            </Col>
            <Col sm={12} style={{ padding: "0" }}>
              <div className="poste-name">
                <h1>{`${offer.titreOffre}`}</h1>
              </div>
              {/* <TextBlock
                title={offer.titreOffre}
                text={" - " + offer.nombrePostes + " poste(s) disponible(s)"}
                /> */}
            </Col>
          </Row>
          <Row className="offer-detail-body">
            {/* <Col sm={1}></Col> */}
            <Col sm={12} md={7} lg={7} style={{ padding: "0" }}>
              {/* {videoLinkExist ? (
                <div
                  class="d-block d-md-none"
                  style={{ width: "auto", height: "auto", padding: "15px" }}
                >
                  <ResponsiveEmbed aspectRatio="16by9">
                    <embed src="https://www.youtube.com/embed/-jGL8wNnU1k" />
                  </ResponsiveEmbed>
                </div>
              ) : null} */}
              <div className="padding-15px apply-for-container isMobile">
                <span
                  className="postulationCTA"
                  size="lg"
                  onClick={() =>
                    childRef.current.handleOfferApplicationVisibility(true)
                  }
                  block
                >
                  Postuler pour cette usine
                  <ReactSVG
                    className="icon-advantage"
                    src={`../../../../SVG/arrow-list.svg`}
                  ></ReactSVG>
                </span>
              </div>
              <ContentBlock
                title="Description du poste"
                description={offer.descriptionOffre}
              />
              <ContentBlockBulletedList
                title="Exemples de tâches à réaliser"
                description={offer.exempleTachesARealiser}
              />
              <ContentBlockBulletedList
                title="Compétences recherchées"
                description={offer.competencesRequises}
              />
              <ContentBlockBulletedList
                title="Formation requise"
                subtitle={
                  offer.equivalenceObligatoire === 1
                    ? "Les diplômes provenant de l'extérieur du Québec, doivent obligatoirement avoir obtenus un équivalence du Ministère."
                    : false
                }
                description={offer.formationRequise}
              />
              {/* <ContentBlockBulletedList
                title="On dit de vous que vous êtes...."
                description={offer.caracteristiquesPersonnelles}
                smallTextLength="smallLength"
              /> */}
              <ContentBlockBulletedList
                title="Conditions d'emploi"
                description={offer.salaire + ";" + offer.autresConditions}
              />
              <div className="padding-15px apply-for-container d-none d-md-block">
                <span
                  className="postulationCTA"
                  size="lg"
                  onClick={() =>
                    childRef.current.handleOfferApplicationVisibility(true)
                  }
                  block
                >
                  Postuler pour cette usine
                  <ReactSVG
                    className="icon-advantage"
                    src={`../../../../SVG/arrow-list.svg`}
                  ></ReactSVG>
                </span>
                <div className="line"></div>
              </div>
            </Col>
            <Col sm={12} md={5} lg={5} className="rightSection">
              {/* {videoLinkExist ? (
                <div
                  className="d-none d-md-block"
                  style={{ width: "auto", height: "auto", padding: "15px" }}
                >
                  <ResponsiveEmbed aspectRatio="16by9">
                    <embed src="https://www.youtube.com/embed/-jGL8wNnU1k" />
                  </ResponsiveEmbed>
                </div>
              ) : null} */}

              {/* <ContentBlock
                title="Notre Client"
                description={offer.nomEmployeur}
              /> */}
              <a
                href={`${GoogleMapLink[offer.villeEmployeur]}`}
                target="_blank"
                className="city-container"
              >
                <WorkIcon className="icon-advantage" />
                <span>{offer.villeEmployeur}</span>
              </a>
              <ContentBlockBulletedList
                title="Environnement de travail"
                description={offer.environnementTravailEmployeur}
              />

              {/* {offer.adresseMapEmployeur ? (
                <div style={{ width: "auto", height: "auto", padding: "15px" }}>
                  <ResponsiveEmbed aspectRatio="16by9">
                    <embed src={offer.adresseMapEmployeur} />
                  </ResponsiveEmbed>
                </div>
              ) : null} */}
              {/* <div className="share-container"> */}
              {/* <span className="title">Partager L'offre</span> */}
              {/* <div className="share-list"> */}
              {/* <ShareList> */}
              {/* <FacebookShareButton>
                    <FacebookIcon size={35} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton>
                    <TwitterIcon size={35} round={true} />
                  </TwitterShareButton>
                  <LinkedinShareButton>
                    <LinkedinIcon size={35} round={true} />
                  </LinkedinShareButton>
                  <EmailShareButton>
                    <EmailIcon size={35} round={true} />
                  </EmailShareButton> */}
              {/* </ShareList> */}
              {/* </div> */}
              {/* </div> */}
              {/* <ContentBlock
                title="Personne Contact"
                contactViaLinkedin={offer.idUtilisateur.urlLinkedin}
              /> */}
              <br />
              {/* setting the correct jobId whether the job is suitable for the contact or not*/}
              <Application
                jobId={
                  emailValue !== ""
                    ? offerNotSuitableForTheContact_OfferId
                    : offer.tags
                }
                email={emailValue}
                ref={childRef}
                formParams={getUrlParameter("form") ? true : false}
              />
              <div className="padding-15px apply-for-container sssss ">
                <span
                  className="postulationCTA"
                  size="lg"
                  onClick={() =>
                    childRef.current.handleOfferApplicationVisibility(true)
                  }
                  block
                >
                  Postuler pour cette usine
                  <ReactSVG
                    className="icon-advantage"
                    src={`../../../../SVG/arrow-list.svg`}
                  ></ReactSVG>
                </span>
              </div>
            </Col>
          </Row>
          <div
            style={{ width: "100%", height: "1em", backgroundColor: "#ffffff" }}
          />
          {/* <div className="padding-15px apply-for-container apply_back_to_offers">
            <div className="soumettre-ma-candidature">
              <h3>Soumettre ma candidature</h3>
              <span
                className="postulationCTA"
                onClick={() =>
                  childRef.current.handleOfferApplicationVisibility(true)
                }
                block
              >
                Postuler sur cet emploi
              </span>
            </div>

            <div className="back-to-offers">
              <div style={{ display: "flex" }}>
                <ArrowBackIosIcon className="arrow-back-icon" />
                <span>
                  Retourner <span style={{ display: "block" }}>aux offres</span>
                </span>
              </div>
            </div>
          </div>
          <Row>
            <OfferFooterBlock
              emailValue={emailValue}
              offerFooterImgSrc={offerFooterImgSrc}
              setEmailValue={setEmailValue}
              applicationRef={childRef}
              fechedOfferList={fechedOfferList}
              actualOffer={offer}
            />
          </Row> */}
        </Container>
      </$OfferDetail>

      <CandidatureSpontanee childRef={childRef} />
    </BaseLayout>
  );
}

export default OfferDetail;
