import React, { useRef } from "react";
import BaseLayout from "../../BaseLayout";
import SEO from "../../seo";
import CategoriesData from "../../../client/data/categoriesData.json";
import { $CandidatureSpontanee } from "./SC_CandidatureSpontanee";
import { ReactSVG } from "react-svg";
import Application from "../offerApplication";
import getUrlParameter from "../../dependentComponent/functions/getParamsValue";
import candidatureSpontaneeData from "./candidatureSpontaneeData";

const CandidatureSpontanee = () => {
  const childRef = useRef();

  return (
    <$CandidatureSpontanee>
      <div className="container-candidature-spontanee">
        <h3>{candidatureSpontaneeData.title}</h3>
        <span className="text">{candidatureSpontaneeData.description}</span>
        <span
          className="postulationCTA"
          size="lg"
          onClick={() =>
            childRef.current.handleOfferApplicationVisibility(true)
          }
          block
        >
          <Application
            // jobId={
            //   emailValue !== ""
            //     ? offerNotSuitableForTheContact_OfferId
            //     : offer.tags
            // }
            // email={emailValue}
            ref={childRef}
            formParams={getUrlParameter("form") ? true : false}
          />
          {candidatureSpontaneeData.button}
          <ReactSVG
            className="icon-advantage"
            src={`../../../../${candidatureSpontaneeData.buttionIcon}`}
          ></ReactSVG>
        </span>
      </div>
    </$CandidatureSpontanee>
  );
};

export default CandidatureSpontanee;
